@import "../variables.scss";

#mainFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $primary;
  padding: 5px 0;
  text-align: center;
  left: 0px;
  // min-height: 35px;
  //   border-top: 1px solid #e9ecef;
  z-index: 100;
  .footerText {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
