@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

$primary: #211130;
$dark-primary: #000000;
$midnight-blue: #191970;
$medium-purple: #9370db;
$white: #ffffff;
$cyan: #00ffff;
$red: #ff0000;
$green: #00ff00;
$ghost: #ffffff59;

$alfaSlabOne: "Alfa Slab One", serif;
$poppins: "Poppins", sans-serif;
$roboto: "Roboto", sans-serif;
$robotoSlab: "Roboto Slab", serif;
$poiret: "Poiret One", sans-serif;
$montserrat: "Montserrat", sans-serif;

.inline {
  display: inline;
}

.tx-10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
}

.tx-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}
.tx-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}
.tx-16 {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}
.tx-18 {
  font-size: 18px;
}
