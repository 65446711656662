@import "variables";
@import "~bootstrap/scss/bootstrap";

body {
  overscroll-behavior-y: none;
}

.skyGradientBackground {
  height: 100vh;
  width: 100%;
  z-index: 0;
  background: linear-gradient(to bottom, #9400d3 0%, #ff0000 100%);
}

#homeSlider {
  margin-top: 50px;
  .home-carousel {
    z-index: 50;
  }
}

.slideCard {
  font-family: $robotoSlab;
  position: absolute;
  width: 42%;
  height: 120px;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  background-color: $white !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  padding: 10px;
  border-radius: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  z-index: 5000;
  .card-body {
    background-color: unset;
    border-radius: 0;
    padding: 10px;
  }
}
.MainTitle {
  font-family: $robotoSlab;
  font-weight: 700;
  font-size: 42px;
  text-align: center;
  color: $white;
  // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.mainSlideTitle {
  font-family: $robotoSlab;
  font-weight: 700;
  font-size: 45px;
  text-align: center;
  width: 100%;
  margin: 0;
  margin-top: -80px;
  line-height: 38px;
  color: $white;
  position: absolute;
  top: 47%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.slideBtn {
  font-family: $robotoSlab;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 5000;
}
.mainSlideDescription {
  font-family: $robotoSlab;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  width: 40%;
  color: $primary;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translate(-50%, -50%);
}
#LearnMoreBtn {
  position: absolute;
  left: 15px;
  color: $white;
  top: 85%;
  display: none;
}
.slideTitle {
  font-family: $robotoSlab;
  font-weight: 700;
  font-size: 62px;
  position: absolute;
  top: 35%;
  color: $white;
  text-align: center;
  width: 100%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.theme-carousel-item {
  background-color: transparent;
  height: 90vh;
  img {
    transition: all 0.5s;
  }

  @media (max-width: 768px) {
    .mainSlideTitle {
      font-size: 35px;
      margin-top: 0px;
      letter-spacing: -1px;
      color: $white;
      top: 13%;
      text-shadow: none;
    }
  }
}

img {
  opacity: 1 !important;
}
img:hover {
  transform: none;
  opacity: unset;
}
.carousel-caption {
  display: block;
  position: absolute;
  width: 50%;
  top: 30%;
  margin: 0 auto;
  animation: unset;
}
@media (max-width: 768px) {
  #root {
    height: 80%;
    overflow-y: hidden;
  }
  #LearnMoreBtn {
    display: inline-block;
    font-weight: 14px;
  }
  .MainTitle {
    font-weight: 700;
    font-size: 32px;
  }
  .slideCard {
    width: 98%;
    bottom: unset;
    top: 65%;
    padding: 10px 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 120px;
    .card-body {
      padding: unset;
    }
  }
  .mainSlideDescription {
    width: 100%;
    left: 0px;
    bottom: unset;
    top: 67%;
    transform: unset;
    font-family: $robotoSlab;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  .mainSlideDescriptionSecond {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .slideTitle {
    font-size: 42px;
    top: 225px;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    color: $white !important;
    filter: unset !important;
    opacity: 1 !important;
  }
  .carousel-control-prev,
  .carousel-control-next {
    top: -100px !important;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
