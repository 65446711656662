@import "../variables";

#MainNavContainer {
  background-color: transparent;
  height: 90px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 600;
}

#contact-button {
  position: absolute;
  top: 25px;
  right: 15px;
  z-index: 100;
}

.appBrand {
  color: $primary;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 50%;
  display: block;
  z-index: 100;
}

.brand-title,
.brand-tagline,
.brand-location {
  width: 100%;
  display: block;
  color: $primary;
  margin-bottom: 0px;
  font-family: $robotoSlab;
}
.brand-title {
  font-size: 42px;
  font-weight: 900;
  line-height: 45px;
  color: $cyan;
}
.brand-tagline {
  font-size: 28px;
  font-weight: 900;
  line-height: 42px;
  color: $white;
}
.brand-location {
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: 900;
  line-height: 28px;
  color: $primary;
}

@media (max-width: 768px) {
  #MainNavContainer {
    background-color: #ff5d00;
    height: 80px;
  }
  .appBrand {
    width: 80%;
    top: 0px;
  }
  .brand-title {
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: -1px;
    margin-top: 10px;
  }
  .brand-tagline {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
  }
  .brand-location {
    font-family: $robotoSlab;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    color: $dark-primary;
    text-shadow: unset;
  }
}
