@import "../variables";
.ContactUsTitleDescContainer {
  height: auto;
  position: absolute;
  bottom: 20px;
  left: 10px;
  width: 95%;
}
.ContactUsTitle {
  font-family: $alfaSlabOne;
}
.ContactUsTitleDesc {
  font-size: 16px;
  font-weight: 900;
}
.ContactUsPhone {
  width: 30%;
  float: left;
  display: inline;
}
.ContactUsPhoneLink {
  text-decoration: none;
  font-size: 12px;
  font-weight: 900;
}
.ContactUsEmail {
  width: 70%;
  float: left;
  display: inline;
}
.ContactUsEmailLink {
  text-decoration: none;
  font-size: 12px;
  font-weight: 900;
}
